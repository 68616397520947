import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: () =>
  //     import(/* webpackChunkName: "mention-legal" */ "../views/ccueil.vue"),
  // },
  {
    path: "/mention-legal",
    name: "mention-legal",
    component: () =>
      import(/* webpackChunkName: "mention-legal" */ "../views/MentionLegal.vue"),
  },
  {
    path: "/protection-donnees",
    name: "protection-donnees",
    component: () =>
      import(/* webpackChunkName: "protection-donnees" */ "../views/ProtectionDonnees.vue"),
  },
  {
    path: "/annonces",
    name: "annonces",
    component: () =>
      import(/* webpackChunkName: "protection-donnees" */ "../views/Annonces.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
