export const langues = {
  EN: {
    message: {
      hello: "Hello",
      banner: {
        titre1: "Welcome to DELATRUST",
        titre2: "Chartered accountancy firm in Luxembourg",
        pourquoi: "Why choose DELATRUST?",
        plus: "More",
        pourquoi1:
          `
<p>DELATRUST, a member of the Luxembourg Order of Chartered Accountants, positions itself as a key partner offering tailored and innovative solutions. With its local expertise, the firm aims to optimize taxation and the management of your business in Luxembourg's competitive environment.</p>`,
        apropos: "Regulatory Compliance",
        apropos1:
          "As a fiduciary company, DELATRUST ensures that all financial operations comply with current laws and regulations, thereby minimizing risks for its clients.",
        apropos2: "Personalized Advice",
        apropos3:
          "DELATRUST offers personalized advice based on the specific needs of each client, whether individuals, SMEs, or large corporations.",
        apropos4: "Reliability and Integrity",
        apropos5:
          "DELATRUST is chosen for its reliability and integrity, essential qualities in the management of funds and assets on behalf of clients.",
        apropos6: "News",
      },
      menu: {
        menu1: "Home",
        menu2: "About Us",
        menu3: "Services",
        menu4: "Contacts",
        menu5: "Client Area",
        menu6: {
          "sousmenu1": "Who are we?",
          "sousmenu2": "Our Objectives",
          "sousmenu3": "Our Experts",
          "sousmenu4": "Our Partner Firms",
          "sousmenu5": "Who are we?"
        }
        
        ,
        menu7: "News",
      },
      apropos: {
        "apropos1": "About Us",
        "apropos2": 
          "DELATRUST is an innovative Luxembourg-based firm specializing in accounting and tax expertise, as well as financial management. We offer solutions for tax optimization, accounting, auditing, and asset management. We pay particular attention to exploring and leveraging business opportunities between Europe and Africa. In this regard, we are committed to providing tailored solutions that perfectly meet the specific needs of each of our clients.",
        "apropos3": "With DELATRUST,Navigate with confidence in an increasingly complex economic environment, thanks to our expertise and personalized solutions!",
        "valeur": {
          "valeur": "Values",
          "valeur1": "Integrity and Confidentiality",
          "valeur2": 
            "We guarantee impeccable professional ethics and rigorously protect the confidentiality of our clients' information.",
          "valeur3": "Innovation and Excellence",
          "valeur4": 
            "We integrate innovation into our solutions while maintaining high-quality standards, continuously training to stay up to date.",
          "valeur5": "Commitment and Personalized Client Service",
          "valeur6": 
            "We offer dedicated and responsive support, with tailored solutions adapted to the specific needs of each client.",
          "valeur7": "International Openness",
          "valeur8": 
            "We promote international collaboration, particularly between Europe and Africa, to seize global business opportunities."
        },
        mission: {
          "mission": "Missions & Visions",
          "mission1": "Accounting, Tax, and Optimization Expertise",
          "mission2": 
            "We ensure accounting, provide tax advisory services, and propose tailored tax optimization strategies to ensure compliance and maximize the tax benefits for our clients.",
          "mission3": "Financial and Asset Management",
          "mission4": 
            "We offer personalized financial and administrative management, anticipate risks, and optimize business performance, as well as the management and valuation of assets.",
          "mission5": "Audit and Certification",
          "mission6": 
            "We conduct legal and contractual audits to ensure the transparency and reliability of financial statements.",
          "mission7": "Business Creation, Restructuring, and Internationalization",
          "mission8": 
            "We assist in the creation and restructuring of businesses, leveraging business opportunities, particularly between Europe and Africa, to foster innovative economic exchanges."
        }
        ,
      },
      service: {
        "service1": "Our Services",
        "service2": "Our services reflect our commitment to providing comprehensive, tailored, and innovative support in an ever-evolving economic environment. We place special emphasis on transparency, performance optimization, and business internationalization.",
        "service3": "Accounting",
        "service4": "DELATRUST offers complete and accurate management of your accounting, including maintaining up-to-date books, preparing financial statements, and producing detailed periodic reports. Our approach aims to provide clear visibility into your finances while optimizing your internal processes by leveraging technological advancements in accounting. Our tailored solutions help you control costs and ensure sound financial management. Contact our experts for more information.",
        "service5": "Taxation and Tax Optimization",
        "service6": "DELATRUST supports you in tax optimization by ensuring compliance with tax obligations and identifying opportunities to reduce your tax burden. We assist you in the planning and management of all your tax filings and develop customized tax strategies to maximize your tax benefits. We also represent your interests before tax authorities. Contact our experts for more information.",
        "service7": "Audit and Certification",
        "service8": "We conduct statutory and contractual audits, ensuring the transparency and reliability of your financial statements and operational processes. Our audit services are designed to assess the compliance of your accounting and financial practices, anticipate risks, and help you improve your internal processes. Through our internal and external audits, you benefit from proactive financial risk management. Contact our experts for more information.",
        "service9": "Financial Management and Asset Management",
        "service10": "DELATRUST supports you in daily financial management and asset valuation by offering customized solutions to optimize your performance. Our services include rigorous monitoring of your financial flows, risk management, and strategic planning to achieve your long-term goals. Contact our experts for more information.",
        "service11": "Corporate and Fiduciary Services",
        "service12": "Our fiduciary services include company domiciliation, project management, consultancy, and secretarial services, providing comprehensive support for the legal and administrative aspects of your business. We guide you in selecting the most appropriate legal structure for your activities, taking into account tax implications. From company creation to dissolution, including restructurings and transformations (mergers and acquisitions), DELATRUST supports you at every stage to ensure the success of your projects. Contact our experts for more information.",
        "service13": "Internationalization and Dispute Assistance",
        "service14": "DELATRUST facilitates your international expansion, particularly in the rapidly growing African markets. We assist you in structuring and optimizing your cross-border operations while ensuring the security of your transactions. In the event of disputes with local partners, we provide strategic assistance and effective representation to defend your interests. Contact our experts for more information.",
        "service15": "These services reflect our commitment to providing our clients with comprehensive, innovative, and tailored solutions that meet their specific needs, while ensuring compliance with applicable standards and regulations."
      }
      
      ,
      contact: {
        contact1: "Contacts",
        contact2: "Visit us",
        contact3: "Call us",
        contact4: "Write to us",
        contact5: "Full name",
        contact6: "Email",
        contact7: "Subject",
        contact8: "Message",
        contact9: "Processing...",
        contact10: "Your message has been sent. Thank you!",
        contact11: "Send"
      }
      ,
      expert: {
        contenu: "<p>The DELATRUST Team</p><p>Our multilingual team, fluent in English, German, and/or French, brings extensive expertise in taxation and compliance with accounting standards such as Lux GAAP, German GAAP, US GAAP, and IFRS. Led by Mawo KOKOU, a qualified Chartered Accountant with many years of experience in Luxembourg and internationally, DELATRUST has established itself as a trusted partner in the Luxembourg market. We are committed to providing tailored solutions that meet the specific needs of our clients in an ever-evolving business environment. By integrating the latest technologies, we ensure efficient, transparent, and real-time accessible services to facilitate the financial management of our clients.</p>",
        expert1: {
          nom: "Mawo KOKOU",
          titreMadal: "The Director",
          fonction: "Director of DELATRUST",
          titre: "Chartered Accountant and Business Management graduate, Mawo brings extensive expertise and a strategic vision to DELATRUST...",
          detail: 
            `<p><strong>Mawo KOKOU</strong><br />Chartered Accountant<br />Director of DELATRUST<br />(Since June 2024)</p>
      <p>As a Chartered Accountant and a graduate in Business Management, Mawo brings extensive expertise and a strategic vision to DELATRUST, particularly in exploring and leveraging the growing opportunities in African markets. Under his leadership, DELATRUST is committed to strengthening its international presence by offering tailored solutions that meet the specific needs of companies interested in the Luxembourg and African markets.</p>
      Under his leadership, DELATRUST aims to expand its international footprint by delivering tailored solutions that address the specific needs of businesses interested in the Luxembourg and African markets.<br />With a particular focus on innovation and value creation, Mawo is committed to leading DELATRUST into new horizons by providing superior services to our clients while exploring the numerous opportunities offered by international markets, particularly African markets. This strategic focus will enable DELATRUST to play a key role in supporting businesses as they navigate the challenges and seize the opportunities of an expanding market.<br />We are confident that Mawo will make a significant contribution to our firm and lead our team successfully to new achievements.`
        }
      }
      
      ,
      cabinetListe: [
        {
          nom: "WASSERBILLIG (LUXEMBOURG)",
          dirigeant: "Manager: Mawo KOKOU",
          adresse: `<p>DELATRUST SARLS<br />7, Esplanade de la Moselle<br />L-6637 Wasserbillig<br />info@delatrust.lu</p>`,
        },
        {
          nom: "LOMÉ (TOGO)",
          dirigeant: "Manager: Kokou TINO",
          adresse: `<p>Y. &amp; Co Consulting<br />Av. Ak&eacute;&iuml; - Bld Concorde,&nbsp;05&nbsp;BP&nbsp;561<br />Lom&eacute;</p>lome@delatrust.lu`,
        },
        {
          nom: "DALLAS (USA)",
          dirigeant: "Manager: Mawo KOKOU",
          adresse: `<p>AEA ACCOUNTING &amp; CONSULTING SERVICES LLC<br />9330 LBJ Freeway, Suite 900<br />75243 Dallas<br />dallas@delatrust.lu</p>`,
        },
        
      ],
      
      cabinet: {
        contenu: "<p>DELATRUST works closely with a network of partner firms in Luxembourg and abroad, thereby strengthening our expertise and capacity to offer comprehensive and tailored solutions to meet the international needs of our clients. These strategic partnerships with experts in taxation, accounting, and business law allow us to ensure high-quality support.</p><p>Our partners share our commitment to excellence and compliance, and we work together to support clients, particularly in the context of cross-border transactions.</p><p>Together, we assist our clients in leveraging business opportunities by providing solid technical support and local expertise that address global financial and tax challenges.</p>",

        cabinet1: {
          nom: "DELATRUST CANADA",
          dirigeant: "Manager: Mawo KOKOU",
          adresse: "Address: ",
        },
        cabinet2: {
          nom: "DELATRUST FRANCE",
          dirigeant: "Manager: Kokou TINO",
          adresse: "Address: ",
        },
        cabinet3: {
          nom: "DELATRUST BELGIUM",
          dirigeant: "Manager: Mawo KOKOU",
          adresse: "Address: ",
        },
      },
      footer: {
        footer1: "Phone",
        footer2: "Email",
        footer3: "Useful Links",
        footer4: "Institute of Chartered Accountants of Luxembourg",
        footer5: "Direct Tax Administration",
        footer6: "Administration of Registration, Domains, and VAT",
        footer7: "Legal Notice",
        footer8: "Data Protection and Confidentiality",
        footer9: "Business Authorization: 10170146/0",
      }
      
      ,
      quisommesnous: {
        contenu: "<p>DELATRUST is an innovative accounting and advisory firm based in Wasserbillig, Grand Duchy of Luxembourg. We specialize in accounting, taxation, and financial management, offering a comprehensive range of services including tax optimization, audit, and asset management. With strict adherence to ethical and regulatory standards, we are particularly dedicated to supporting investment funds, financial holding companies, family wealth management firms, and commercial companies, especially those seeking to leverage business opportunities between Europe and Africa.</p>"
      },
      mention: {
        titre: "Legal Notice",
        contenu: `
          <p>Legal Notice of the company <strong>DELATRUST</strong></p>
          <p>&nbsp;</p>
          <p>Company Name: DELATRUST Sàrls<br /> 
          Registered Office: 7, Esplanade de la Moselle <br /> 
          L-6637 Wasserbillig, Luxembourg<br /> 
          Luxembourg Trade Register: B288732<br /> 
          VAT Number: LU35928783<br /> 
          Business Authorization: 10170146/0</p>
          <p>Phone: +352 691 141 766<br /> 
          WhatsApp: +352 691 141 766</p>
          <p>Website: <a href="http://www.delatrust.lu">www.delatrust.lu</a><br /> 
          Email: <a href="mailto:info@delatrust.lu">info@delatrust.lu</a></p>
          <p>&nbsp;</p>
          <p><strong>Terms and Conditions of Use of the Website</strong></p>
          <p>DELATRUST, a chartered accounting and tax firm registered with the Order of Chartered Accountants of Luxembourg, provides its website www.delatrust.lu as a platform to present the company and its services. This website includes informational, advertising, and marketing content. The website content may be updated or modified at any time without prior notice.</p>
          <p>Use of the site implies acceptance of the terms and conditions of use. DELATRUST reserves the right to modify these terms at any time, with the updates applying immediately to all users.</p>
          <p>&nbsp;</p>
          <p><strong>Access and Content of the Website</strong></p>
          <p>DELATRUST Sàrls reserves the right to update, modify, suspend, or interrupt the website without prior notice for maintenance or other necessary reasons.</p>
          <p>&nbsp;</p>
          <p><strong>Intellectual Property</strong></p>
          <p>All elements on the website (logos, trademarks, photos, videos, texts, drawings, graphics, layout) are the exclusive property of DELATRUST. Any reproduction, distribution, modification, or use, in whole or in part, of the content is strictly prohibited without prior authorization.</p>
          <p>&nbsp;</p>
          <p><strong>Links to Other Websites</strong></p>
          <p>The DELATRUST website may contain links to third-party websites. DELATRUST does not approve or control the content of these websites and disclaims any responsibility for the information and services offered by these third parties.</p>
        `,
        politique: `
          <p>The data collected via the forms on the site is only stored after the user's validation, which constitutes explicit consent for their processing. You have the right to consult, correct, or delete your personal data by sending a request by email to info@delatrust.lu or by postal mail to the registered office address.</p>
          <p>However, DELATRUST Sàrls reserves the right not to retain the personal data of users and may delete them at any time without prior notice.</p>
          <p><strong>Applicable Law</strong></p>
          <p>These terms are subject to Luxembourg law. In case of a dispute regarding the application or interpretation of these terms, the competent courts of the Grand Duchy of Luxembourg will have jurisdiction.</p>
        `,
        titrePolitique: "Data Protection and Privacy"
      }
      ,
      info: [
        {
          title:
            "December 31: Deadline for filing the Income Tax, Commercial Tax, and Wealth Tax Declaration for municipalities",
          content:
            "Remember that the tax declaration must be submitted to the competent tax office before December 31 of the year following the relevant tax year. Don’t delay in preparing your documents. Contact us to learn more!",
        },
        {
          title: "July 31: Deadline for filing the annual accounts",
          content:
            "Annual accounts must be filed within one month of their approval, and no later than 7 months after the end of the fiscal year for individual traders or after the close of the fiscal year.",
        },
        {
          title:
            "Before March 1 or May 1: Deadline for filing the annual VAT return",
          content:
            "The deadline for filing the annual VAT return depends on the total turnover excluding tax, unless a different decision is made by the Administration of Registration and Domains (AED). Contact us to learn more!",
        },
        {
          title: "Tax obligations in Luxembourg",
          content:
            "The creation and management of a business in Luxembourg are subject to various taxes and fiscal obligations, governed by three main administrations: the Administration of Direct Contributions (ACD), ",
        },
      ],
      sendmail: {
        confirmation: "Do you confirm sending this message?",
        textConfirmation: "Yes",
        textCancel: "No",
        textSuccess: "DELATRUST Luxembourg thanks you for your message. An expert will contact you as soon as possible."

      },
      information: {
        info: "Details of the ad",
        info1: "Select an ad from the list on the left to see the details here."
      }
      
      
    },
  },
  DE: {
    message: {
      hello: "Guten Tag",
      banner: {
        titre1: "Willkommen bei DELATRUST",
        titre2: "Steuerberatungskanzlei in Luxemburg",
        pourquoi: "Warum DELATRUST wählen?",
        plus: "Mehr",
        pourquoi1:
         `
<p>Die Kanzlei DELATRUST, Mitglied der Kammer der Wirtschaftspr&uuml;fer in Luxemburg, positioniert sich als ein Schl&uuml;sselpartner mit ma&szlig;geschneiderten und innovativen L&ouml;sungen. Dank ihrer lokalen Expertise zielt sie darauf ab, die Steueroptimierung und das Management Ihrer Gesch&auml;fte in einem wettbewerbsintensiven luxemburgischen Umfeld zu verbessern.</p>`,
        apropos: "Regulatorische Konformität",
        apropos1:
          "Als Treuhandgesellschaft stellt DELATRUST sicher, dass alle Finanzoperationen den geltenden Gesetzen und Vorschriften entsprechen, um Risiken für die Kunden zu minimieren.",
        apropos2: "Personalisierte Beratung",
        apropos3:
          "DELATRUST bietet maßgeschneiderte Beratung, die auf die spezifischen Bedürfnisse jedes Kunden abgestimmt ist, sei es für Privatpersonen, KMUs oder große Unternehmen.",
        apropos4: "Zuverlässigkeit und Integrität",
        apropos5:
          "DELATRUST wird für seine Zuverlässigkeit und Integrität gewählt, wesentliche Qualitäten bei der Verwaltung von Fonds und Vermögenswerten im Auftrag von Kunden.",
        apropos6: "Nachrichten",
      },
      menu: {
        menu1: "Startseite",
        menu2: "Über uns",
        menu3: "Dienstleistungen",
        menu4: "Kontakte",
        menu5: "Kundenbereich",
        menu6: {
          "sousmenu1": "Wer sind wir?",
          "sousmenu2": "Unsere Ziele",
          "sousmenu3": "Unsere Expertens.",
          "sousmenu4": "Unsere Partnerkanzleien",
          "sousmenu5": "Wer sind wir?"
        },
        menu7: "Nachrichten",
 
        
        
      },
      apropos: {
        "apropos1": "Über uns",
        "apropos2": 
          `Die Kanzlei DELATRUST, Mitglied der luxemburgischen Expertenkammer (OEC), positioniert sich als vertrauenswürdiger Partner, um Ihre Aktivitäten in den Bereichen Organisation, Finanzen und Management zu unterstützen. DELATRUST zeichnet sich durch lokale Expertise, maßgeschneiderte und innovative Lösungen, ein engagiertes Expertenteam und die Einhaltung der regulatorischen Vorgaben in Luxemburg aus.

Mit der Wahl von DELATRUST profitieren Sie von professionellen Dienstleistungen, die dazu beitragen, die Verwaltung und Nachhaltigkeit Ihrer Geschäfte in einem wettbewerbsorientierten Umfeld zu optimieren.`,
        "apropos3": "Mit DELATRUST,Navigieren Sie mit Vertrauen in einer zunehmend komplexen Wirtschaftswelt, dank unserer Expertise und unserer maßgeschneiderten Lösungen!",
        "valeur": {
          "valeur": "Werte",
          "valeur1": "Integrität und Vertraulichkeit",
          "valeur2": 
            "Wir garantieren eine tadellose berufliche Ethik und schützen rigoros die Vertraulichkeit der Informationen unserer Kunden.",
          "valeur3": "Innovation und Exzellenz",
          "valeur4": 
            "Wir integrieren Innovation in unsere Lösungen und halten dabei hohe Qualitätsstandards aufrecht, indem wir uns kontinuierlich weiterbilden, um auf dem neuesten Stand zu bleiben.",
          "valeur5": "Engagement und maßgeschneiderter Kundenservice",
          "valeur6": 
            "Wir bieten engagierte und reaktionsschnelle Unterstützung mit maßgeschneiderten Lösungen, die auf die spezifischen Bedürfnisse jedes Kunden zugeschnitten sind.",
          "valeur7": "Internationale Offenheit",
          "valeur8": 
            "Wir fördern die internationale Zusammenarbeit, insbesondere zwischen Europa und Afrika, um globale Geschäftsmöglichkeiten zu nutzen."
        },
        mission: {
          "mission": "Missionen & Visionen",
          "mission1": "Buchhaltungs-, Steuer- und Optimierungsexpertise",
          "mission2": 
            "Wir gewährleisten die Buchhaltung, bieten Steuerberatung an und entwickeln maßgeschneiderte Strategien zur Steueroptimierung, um die Compliance sicherzustellen und die steuerlichen Vorteile unserer Kunden zu maximieren.",
          "mission3": "Finanz- und Vermögensverwaltung",
          "mission4": 
            "Wir bieten eine maßgeschneiderte Finanz- und Verwaltungsverwaltung an, antizipieren Risiken und optimieren die Unternehmensleistung sowie die Verwaltung und Wertsteigerung von Vermögenswerten.",
          "mission5": "Prüfung und Zertifizierung",
          "mission6": 
            "Wir führen gesetzliche und vertragliche Prüfungen durch, um die Transparenz und Zuverlässigkeit der Finanzberichte zu gewährleisten.",
          "mission7": "Unternehmensgründung, -restrukturierung und Internationalisierung",
          "mission8": 
            "Wir begleiten die Gründung und Restrukturierung von Unternehmen, indem wir Geschäftsmöglichkeiten bewerten, insbesondere zwischen Europa und Afrika, um innovative wirtschaftliche Austauschbeziehungen zu fördern."
        }
        ,
      },
      service: {
        "service1": "Unsere Dienstleistungen",
        "service2": "Unsere Dienstleistungen spiegeln unser Engagement wider, umfassende, maßgeschneiderte und innovative Unterstützung in einem sich ständig wandelnden wirtschaftlichen Umfeld zu bieten. Wir legen besonderen Wert auf Transparenz, Leistungsoptimierung und Internationalisierung von Unternehmen.",
        "service3": "Buchhaltung",
        "service4": "DELATRUST bietet eine umfassende und präzise Verwaltung Ihrer Buchhaltung, einschließlich der laufenden Pflege Ihrer Bücher, der Erstellung von Finanzberichten und der Bereitstellung detaillierter periodischer Berichte. Unser Ansatz zielt darauf ab, Ihnen eine klare Sicht auf Ihre Finanzen zu bieten und gleichzeitig Ihre internen Prozesse durch den Einsatz technologischer Fortschritte im Bereich der Buchhaltung zu optimieren. Unsere maßgeschneiderten Lösungen helfen Ihnen, Ihre Kosten zu kontrollieren und eine gesunde Finanzverwaltung sicherzustellen. Kontaktieren Sie unsere Experten für weitere Informationen.",
        "service5": "Steuern und Steueroptimierung",
        "service6": "DELATRUST unterstützt Sie bei der Steueroptimierung, indem nicht nur die Einhaltung der steuerlichen Verpflichtungen gewährleistet wird, sondern auch Möglichkeiten zur Reduzierung Ihrer Steuerlast aufgezeigt werden. Wir helfen Ihnen bei der Planung und Verwaltung aller Ihrer Steuererklärungen und entwickeln maßgeschneiderte Steuerstrategien, um Ihre steuerlichen Vorteile zu maximieren. Darüber hinaus vertreten wir Ihre Interessen gegenüber den Steuerbehörden. Kontaktieren Sie unsere Experten für weitere Informationen.",
        "service7": "Audit und Zertifizierung",
        "service8": "Wir führen gesetzliche und vertragliche Audits durch, um die Transparenz und Zuverlässigkeit Ihrer Finanzberichte und Betriebsprozesse sicherzustellen. Unsere Audit-Dienstleistungen zielen darauf ab, die Konformität Ihrer buchhalterischen und finanziellen Praktiken zu bewerten, Risiken vorherzusehen und Ihnen zu helfen, Ihre internen Prozesse zu verbessern. Dank unserer internen und externen Audits profitieren Sie von einem proaktiven Finanzrisikomanagement. Kontaktieren Sie unsere Experten für weitere Informationen.",
        "service9": "Finanzmanagement und Vermögensverwaltung",
        "service10": "DELATRUST unterstützt Sie im täglichen Finanzmanagement und bei der Wertsteigerung Ihrer Vermögenswerte, indem wir maßgeschneiderte Lösungen zur Optimierung Ihrer Leistungen anbieten. Unsere Dienstleistungen umfassen die sorgfältige Überwachung Ihrer Finanzflüsse, das Risikomanagement und die strategische Planung, um Ihre langfristigen Ziele zu erreichen. Kontaktieren Sie unsere Experten für weitere Informationen.",
        "service11": "Corporate- und Treuhanddienste",
        "service12": "Unsere Treuhanddienste umfassen Firmensitzdienste, Projektmanagement, Beratung und Sekretariatsdienste und bieten somit eine umfassende Unterstützung für die rechtlichen und administrativen Aspekte Ihres Unternehmens. Wir begleiten Sie bei der Auswahl der am besten geeigneten Rechtsform für Ihre Aktivitäten unter Berücksichtigung steuerlicher Auswirkungen. Von der Unternehmensgründung über Umstrukturierungen und Umwandlungen (Fusionen und Übernahmen) bis hin zur Auflösung begleitet DELATRUST Sie in jeder Phase, um den Erfolg Ihrer Projekte zu gewährleisten. Kontaktieren Sie unsere Experten für weitere Informationen.",
        "service13": "Internationalisierung und Streitbeilegung",
        "service14": "DELATRUST erleichtert Ihre internationale Expansion, insbesondere auf den stark wachsenden afrikanischen Märkten. Wir unterstützen Sie bei der Strukturierung und Optimierung Ihrer grenzüberschreitenden Aktivitäten und sorgen für die Sicherheit Ihrer Transaktionen. Im Falle von Streitigkeiten mit lokalen Partnern stehen wir Ihnen zur Seite und bieten strategische Unterstützung sowie eine effektive Vertretung zur Verteidigung Ihrer Interessen. Kontaktieren Sie unsere Experten für weitere Informationen.",
        "service15": "Diese Dienstleistungen spiegeln unser Bestreben wider, unseren Kunden umfassende, innovative und maßgeschneiderte Lösungen zu bieten, die ihren spezifischen Bedürfnissen entsprechen und gleichzeitig die Einhaltung der geltenden Normen und Vorschriften gewährleisten."
      }
      
      ,
      contact: {
        contact1: "Kontakte",
        contact2: "Besuchen Sie uns",
        contact3: "Rufen Sie uns an",
        contact4: "Schreiben Sie uns",
        contact5: "Vor- und Nachname",
        contact6: "E-Mail",
        contact7: "Betreff",
        contact8: "Nachricht",
        contact9: "Wird bearbeitet...",
        contact10: "Ihre Nachricht wurde gesendet. Vielen Dank!",
        contact11: "Senden"
      }
      ,
        expert: {
          contenu: "<p>Das Team von DELATRUST</p><p>Unser multilinguales Team, das Englisch, Deutsch und/oder Französisch beherrscht, bringt umfassende Expertise in den Bereichen Steuerrecht und Compliance mit Buchhaltungsstandards wie Lux GAAP, German GAAP, US GAAP und IFRS. Geleitet von Mawo KOKOU, einem qualifizierten Wirtschaftsprüfer mit vielen Jahren Erfahrung in Luxemburg und international, hat sich DELATRUST als vertrauenswürdiger Partner auf dem luxemburgischen Markt etabliert. Wir verpflichten uns, maßgeschneiderte Lösungen anzubieten, die den Bedürfnissen unserer Kunden in einer sich ständig wandelnden Geschäftsumgebung entsprechen. Durch die Integration neuester Technologien gewährleisten wir effiziente, transparente und in Echtzeit zugängliche Dienstleistungen, um das Finanzmanagement unserer Kunden zu erleichtern.</p>",
          expert1: {
            nom: "Mawo KOKOU",
            titreMadal: "Der Direktor",
            fonction: "Direktor von DELATRUST",
            titre: "Wirtschaftsprüfer und Absolvent in Unternehmensführung, Mawo bringt umfassende Expertise und eine strategische Vision bei DELATRUST ein...",
            detail: 
              `<p><strong>Mawo KOKOU</strong><br />Wirtschaftsprüfer<br />Direktor von DELATRUST<br />(Seit Juni 2024)</p>
        <p>Als Wirtschaftsprüfer und Absolvent in Unternehmensführung bringt Mawo eine umfassende Expertise und strategische Vision bei DELATRUST ein, insbesondere im Hinblick auf die Erkundung und Nutzung wachsender Chancen auf afrikanischen Märkten. Unter seiner Leitung setzt sich DELATRUST dafür ein, seine internationale Präsenz zu stärken, indem maßgeschneiderte Lösungen angeboten werden, die den spezifischen Bedürfnissen von Unternehmen gerecht werden, die an den Märkten von Luxemburg und Afrika interessiert sind.</p>
        Unter seiner Leitung strebt DELATRUST danach, seine internationale Präsenz auszubauen, indem maßgeschneiderte Lösungen bereitgestellt werden, die den spezifischen Anforderungen von Unternehmen entsprechen, die an den Märkten von Luxemburg und Afrika interessiert sind.<br />Mit einem besonderen Fokus auf Innovation und Wertschöpfung setzt sich Mawo dafür ein, DELATRUST in neue Horizonte zu führen, indem er erstklassige Dienstleistungen für unsere Kunden bereitstellt und die zahlreichen Chancen der internationalen Märkte, insbesondere der afrikanischen Märkte, erkundet. Dieser strategische Fokus wird es DELATRUST ermöglichen, eine Schlüsselrolle bei der Begleitung von Unternehmen angesichts der Herausforderungen und Chancen eines sich entwickelnden Marktes zu spielen.<br />Wir sind überzeugt, dass Mawo einen bedeutenden Beitrag zu unserem Unternehmen leisten wird und unser Team erfolgreich zu neuen Erfolgen führen wird.`
          }
        }
        
        
      ,
      cabinetListe: [
        {
          nom: "WASSERBILLIG (LUXEMBURG)",
          dirigeant: "Geschäftsführer: Mawo KOKOU",
          adresse: `<p>DELATRUST SARLS<br />7, Esplanade de la Moselle<br />L-6637 Wasserbillig<br />info@delatrust.lu</p>`,
        },
        {
          nom: "LOMÉ (TOGO)",
          dirigeant: "Geschäftsführer: Kokou TINO",
          adresse: `<p>Y. &amp; Co Consulting<br />Av. Ak&eacute;&iuml; - Bld Concorde,&nbsp;05&nbsp;BP&nbsp;561<br />Lom&eacute;o</p>lome@delatrust.lu`,
        },
        {
          nom: "DALLAS (USA)",
          dirigeant: "Geschäftsführer: Mawo KOKOU",
          adresse: `<p>AEA ACCOUNTING &amp; CONSULTING SERVICES LLC<br />9330 LBJ Freeway, Suite 900<br />75243 Dallas<br />dallas@delatrust.lu</p>`,
        },
        
      ],
      
      cabinet: {
        contenu: "<p>DELATRUST arbeitet eng mit einem Netzwerk von Partnerkanzleien in Luxemburg und im Ausland zusammen, wodurch wir unser Fachwissen und unsere Fähigkeit stärken, umfassende und maßgeschneiderte Lösungen für die internationalen Bedürfnisse unserer Kunden anzubieten. Diese strategischen Partnerschaften mit Experten in den Bereichen Steuern, Buchhaltung und Wirtschaftsrecht ermöglichen es uns, eine qualitativ hochwertige Betreuung zu gewährleisten.</p><p>Unsere Partner teilen unser Engagement für Exzellenz und Compliance, und wir arbeiten zusammen, um Kunden insbesondere bei grenzüberschreitenden Transaktionen zu unterstützen.</p><p>Gemeinsam begleiten wir unsere Kunden bei der Nutzung von Geschäftsmöglichkeiten und bieten dabei fundierte technische Unterstützung und lokale Expertise, die den globalen finanziellen und steuerlichen Herausforderungen gerecht wird.</p>",

        cabinet1: {
          nom: "DELATRUST KANADA",
          dirigeant: "Leiter: Mawo KOKOU",
          adresse: "Adresse: ",
        },
        cabinet2: {
          nom: "DELATRUST FRANKREICH",
          dirigeant: "Leiter: Kokou TINO",
          adresse: "Adresse: ",
        },
        cabinet3: {
          nom: "DELATRUST BELGIEN",
          dirigeant: "Leiter: Mawo KOKOU",
          adresse: "Adresse: ",
        },
      },
      footer: {
        footer1: "Telefon",
        footer2: "E-Mail",
        footer3: "Nützliche Links",
        footer4: "Ordnung der Wirtschaftsprüfer von Luxemburg",
        footer5: "Verwaltung der direkten Steuern",
        footer6: "Verwaltung der Registrierung, der Domänen und der Mehrwertsteuer",
        footer7: "Rechtliche Hinweise",
        footer8: "Datenschutz und Vertraulichkeit",
        footer9: "Betriebsgenehmigung: 10170146/0",
      }
      
      ,
      quisommesnous: {
        contenu: "<p>DELATRUST ist eine innovative Wirtschaftsprüfungsgesellschaft mit Sitz in Wasserbillig im Großherzogtum Luxemburg. Wir sind auf Buchhaltung, Steuern und Finanzmanagement spezialisiert und bieten ein umfassendes Dienstleistungsspektrum, darunter Steueroptimierung, Buchprüfungen und Vermögensverwaltung. Mit strenger Einhaltung ethischer und regulatorischer Standards widmen wir uns insbesondere der Unterstützung von Investmentfonds, Finanzbeteiligungsgesellschaften, Familienvermögensverwaltungsgesellschaften und Handelsunternehmen, insbesondere solcher, die Geschäftsmöglichkeiten zwischen Europa und Afrika nutzen möchten.</p>"
      },
      mention: {
        titre: "Rechtliche Hinweise",
        contenu: `
          <p>Rechtliche Hinweise des Unternehmens <strong>DELATRUST</strong></p>
          <p>&nbsp;</p>
          <p>Firma: DELATRUST Sàrls<br /> 
          Firmensitz: 7, Esplanade de la Moselle <br /> 
          L-6637 Wasserbillig, Luxemburg<br /> 
          Handelsregister Luxemburg: B288732<br /> 
          Umsatzsteuer-Identifikationsnummer: LU35928783<br /> 
          Zulassung: 10170146/0</p>
          <p>Telefon: +352 691 141 766<br /> 
          WhatsApp: +352 691 141 766</p>
          <p>Website: <a href="http://www.delatrust.lu">www.delatrust.lu</a><br /> 
          E-Mail: <a href="mailto:info@delatrust.lu">info@delatrust.lu</a></p>
          <p>&nbsp;</p>
          <p><strong>Allgemeine Nutzungsbedingungen der Website</strong></p>
          <p>DELATRUST, eine in das luxemburgische Expertenverzeichnis eingetragene Wirtschaftsprüfungsgesellschaft, stellt ihre Website www.delatrust.lu als Plattform zur Präsentation des Unternehmens und seiner Dienstleistungen zur Verfügung. Diese Website enthält informierende, werbliche und marketingtechnische Inhalte. Der Inhalt der Website kann jederzeit ohne Vorankündigung aktualisiert oder geändert werden.</p>
          <p>Die Nutzung der Website setzt die Zustimmung zu den Allgemeinen Nutzungsbedingungen voraus. DELATRUST behält sich das Recht vor, diese Bedingungen jederzeit zu ändern, wobei die Änderungen sofort für alle Benutzer gelten.</p>
          <p>&nbsp;</p>
          <p><strong>Zugang und Inhalt der Website</strong></p>
          <p>DELATRUST Sàrls behält sich das Recht vor, die Website ohne Vorankündigung zu aktualisieren, zu ändern, auszusetzen oder zu unterbrechen, sei es aus Wartungsgründen oder aus anderen notwendigen Gründen.</p>
          <p>&nbsp;</p>
          <p><strong>Geistiges Eigentum</strong></p>
          <p>Alle Elemente auf der Website (Logos, Marken, Fotos, Videos, Texte, Zeichnungen, Grafiken, Layout) sind alleiniges Eigentum von DELATRUST. Jede Vervielfältigung, Verbreitung, Modifikation oder Nutzung, ganz oder teilweise, des Inhalts ist ohne vorherige Genehmigung strengstens untersagt.</p>
          <p>&nbsp;</p>
          <p><strong>Links zu anderen Websites</strong></p>
          <p>Die DELATRUST-Website kann Links zu Drittanbieter-Websites enthalten. DELATRUST billigt oder kontrolliert den Inhalt dieser Websites nicht und übernimmt keine Verantwortung für die Informationen und Dienstleistungen, die von diesen Drittanbietern angeboten werden.</p>
        `,
        politique: `
          <p>Die über die Formulare auf der Website gesammelten Daten werden nur nach Bestätigung durch den Benutzer gespeichert, was eine ausdrückliche Zustimmung zur Verarbeitung darstellt. Sie haben das Recht, Ihre persönlichen Daten einzusehen, zu korrigieren oder zu löschen, indem Sie eine Anfrage per E-Mail an info@delatrust.lu oder per Post an die Adresse des Firmensitzes senden.</p>
          <p>Dennoch behält sich die Firma DELATRUST Sàrls das Recht vor, die persönlichen Daten der Benutzer nicht zu speichern und diese jederzeit ohne Vorankündigung zu löschen.</p>
          <p><strong>Anwendbares Recht</strong></p>
          <p>Diese Bedingungen unterliegen dem luxemburgischen Recht. Im Falle von Streitigkeiten bezüglich der Anwendung oder Auslegung dieser Bedingungen sind die zuständigen Gerichte des Großherzogtums Luxemburg zuständig.</p>
        `,
        titrePolitique: "Datenschutz und Vertraulichkeit"
      },
      
      info: [
        {
          title:
            "31. Dezember: Frist zur Einreichung der Steuererklärung für Einkommenssteuer, Gewerbesteuer und Vermögenssteuer der Gemeinden",
          content:
            "Denken Sie daran, dass die Steuererklärung bis spätestens zum 31. Dezember des auf das betreffende Steuerjahr folgenden Jahres beim zuständigen Finanzamt eingereicht werden muss. Verzögern Sie nicht die Vorbereitung Ihrer Unterlagen. Kontaktieren Sie uns, um mehr zu erfahren!",
        },
        {
          title: "31. Juli: Frist zur Einreichung des Jahresabschlusses",
          content:
            "Der Jahresabschluss muss innerhalb eines Monats nach seiner Genehmigung eingereicht werden, spätestens jedoch 7 Monate nach dem Ende des Geschäftsjahres für Einzelhändler oder nach Abschluss des Geschäftsjahres.",
        },
        {
          title:
            "Vor dem 1. März oder 1. Mai: Frist zur Einreichung der jährlichen Mehrwertsteuererklärung",
          content:
            "Die Frist zur Einreichung der jährlichen Mehrwertsteuererklärung hängt vom steuerfreien Gesamtumsatz ab, es sei denn, die Verwaltung der Registrierung und der Domänen (AED) trifft eine andere Entscheidung. Kontaktieren Sie uns, um mehr zu erfahren!",
        },
        {
          title: "Steuerliche Verpflichtungen in Luxemburg",
          content:
            "Die Gründung und Verwaltung eines Unternehmens in Luxemburg unterliegen verschiedenen Steuern und steuerlichen Verpflichtungen, die von drei Hauptverwaltungen geregelt werden: der Verwaltung der direkten Steuern (ACD), ",
        },
      ],
      "sendmail": {
    "confirmation": "Möchten Sie diese Nachricht wirklich senden?",
    "textConfirmation": "Ja",
    "textCancel": "Nein",
    textSuccess: "DELATRUST Luxembourg bedankt sich für Ihre Nachricht. Ein Experte wird Sie so bald wie möglich kontaktieren."

  },
  information: {
    info: "Details der Anzeige",
    info1: "Wählen Sie eine Anzeige aus der Liste links, um die Details hier zu sehen."
  }
  
    },
    
  },
  FR: {
    message: {
      hello: "Bonjour",
      banner: {
        titre1: "Bienvenue à DELATRUST",
        titre2: "Cabinet d´expertise comptable au Luxembourg",
        pourquoi: "Pourquoi choisir DELATRUST?",
        plus: "Plus",
        pourquoi1:
          `

<p>Le cabinet DELATRUST, membre de l'Ordre des Experts-Comptables au Luxembourg, se positionne comme un partenaire cl&eacute; avec des solutions personnalis&eacute;es et innovantes gr&acirc;ce &agrave; son expertise locale, visant &agrave; optimiser la fiscalit&eacute; et la gestion de vos affaires dans un environnement luxembourgeois&nbsp;concurrentiel.</p>`,
        apropos: "Conformité Réglementaire",
        apropos1:
          "En tant qu'entreprise fiduciaire, DELATRUST assure que toutes les opérations financières sont conformes aux lois et régulations en vigueur, minimisant ainsi les risques pour ses clients.",
        apropos2: "Conseils Personnalisés",
        apropos3:
          "DELATRUST offre des conseils personnalisés en fonction des besoins spécifiques de chaque client, qu'il s'agisse de particuliers, de PME, ou de grandes entreprises.",
        apropos4: "Fiabilité et Intégrité",
        apropos5:
          "DELATRUST est choisie pour sa fiabilité et son intégrité, des qualités essentielles dans la gestion de fonds et d'actifs pour le compte de clients.",
        apropos6: "Actualités",
      },
      menu: {
        menu1: "Accueil",
        menu2: "À propos",
        menu3: "Services",
        menu4: "Contacts",
        menu5: "Espace client",
        menu6: {
          sousmenu1: "Qui sommes nous ?",
          sousmenu2: "Nos Objectifs",
          sousmenu3: "Nos experts",
          sousmenu4: "Nos cabinets partenaires ",
          sousmenu5: "Qui sommes nous ?",
        },
        menu7: "Actualités",
        
      },
      quisommesnous:{
        contenu : "<p>DELATRUST est un cabinet d'expertise comptable innovant, bas&eacute; &agrave; Wasserbillig, au Grand-Duch&eacute; de Luxembourg. Nous sommes sp&eacute;cialis&eacute;s dans la comptabilit&eacute;, la fiscalit&eacute; et la gestion financi&egrave;re, et proposons une gamme compl&egrave;te de services, notamment l'optimisation fiscale, la r&eacute;vision comptable et la gestion d'actifs. En adh&eacute;rant strictement aux normes &eacute;thiques et r&eacute;glementaires, nous nous consacrons particuli&egrave;rement &agrave; l'accompagnement des fonds d'investissement, des soci&eacute;t&eacute;s de participations financi&egrave;res, des soci&eacute;t&eacute;s de gestion de patrimoine familial et des soci&eacute;t&eacute;s commerciales, notamment celles qui visent &agrave; exploiter les opportunit&eacute;s d'affaires entre l'Europe et l'Afrique.</p>"
      },
      apropos: {
        apropos1: "À propos de nous",
        apropos2:
          "DELATRUST est un cabinet luxembourgeois innovant, spécialisé en expertise comptable et fiscale, ainsi qu'en gestion financière. Nous offrons notamment des solutions d'optimisation fiscale, de comptabilité, d'audit et de gestion d'actifs. Nous accordons une attention particulière à l'exploration et à la valorisation des opportunités d'affaires entre l'Europe et l'Afrique. Dans cette optique, nous nous engageons à fournir des solutions sur mesure, parfaitement adaptées aux besoins spécifiques de chacun de nos clients.",
          apropos3: "«Avec DELATRUST,Naviguez en toute confiance dans un environnement économique de plus en plus complexe, grâce à notre expertise et à nos solutions personnalisées!",
          valeur: {
          valeur: "Valeurs",
          valeur1: "Intégrité et Confidentialité ",
          valeur2:
            "Nous garantissons une éthique professionnelle irréprochable et protégeons rigoureusement la confidentialité des informations de nos clients.",
          valeur3: "Innovation et Excellence ",
          valeur4:
            "Nous intégrons l'innovation dans nos solutions tout en maintenant des standards élevés de qualité, en nous formant continuellement pour rester à jour.",
          valeur5: "Engagement et Service Client Personnalisé ",
          valeur6:
            "Nous offrons un accompagnement dévoué et réactif, avec des solutions sur mesure adaptées aux besoins spécifiques de chaque client.",
            valeur7: "Ouverture Internationale",
            valeur8:
              "Nous favorisons la collaboration internationale, notamment entre l'Europe et l'Afrique, afin de saisir des opportunités d'affaires globales",
            
          },
        mission: {
          mission: "Missions & Visions",
          mission1: "Expertise comptable, fiscale et optimisation",
          mission2:
            "Nous assurons la comptabilité, le conseil fiscal et proposons des stratégies d'optimisation fiscale sur mesure pour garantir la conformité et maximiser les avantages fiscaux de nos clients.",
          mission3: "Gestion financière et d'actifs ",
          mission4:
            "Nous offrons une gestion financière et administrative personnalisée, anticipons les risques, et optimisons la performance des entreprises, ainsi que la gestion et valorisation des actifs.",
          mission5: "Audit et certification ",
          mission6:
            "Nous réalisons des audits légaux et contractuels pour garantir la transparence et la fiabilité des états financiers",
            mission7: "Création, restructuration d'entreprises et internationalisation  ",
            mission8:
              "Nous accompagnons la création et la restructuration d'entreprises, en valorisant les opportunités d'affaires, notamment entre l'Europe et l'Afrique, pour favoriser des échanges économiques innovants.",
          
          },
      },
      service: {
        service1: "Nos services",
        service2: "Nos services reflètent notre engagement à offrir un accompagnement complet, personnalisé et innovant, dans un contexte économique en constante évolution. Nous mettons un accent particulier sur la transparence, l’optimisation de la performance et l’internationalisation des affaires",
        service3: "Comptabilité",
        service4:
          "DELATRUST offre une gestion complète et rigoureuse de votre comptabilité, incluant la tenue à jour de vos livres comptables, la préparation des états financiers et la production de rapports périodiques détaillés. Notre approche vise à offrir une visibilité claire de vos finances, tout en optimisant vos processus internes en tenant compte des avancées technologiques en matière de comptabilité. Nos solutions personnalisées vous aident à maîtriser vos coûts et à assurer une gestion financière saine. Contactez nos experts pour en savoir plus.",
        service5: "Fiscalité et Optimisation fiscale  ",
        service6:
          "DELATRUST vous accompagne dans l’optimisation fiscale, garantissant non seulement la conformité avec les obligations fiscales, mais aussi l’identification d’opportunités pour réduire vos charges fiscales. Nous vous assistons dans la planification et la gestion de toutes vos déclarations fiscales et développons des stratégies fiscales sur mesure pour maximiser vos avantages fiscaux. Nous assurons également la représentation de vos intérêts vis-à-vis des autorités fiscales. Contactez nos experts pour en savoir plus.",
        service7: "Audit et Certification",
        service8:
          "Nous réalisons des audits légaux et contractuels, en veillant à la transparence et à la fiabilité de vos états financiers et de vos processus opérationnels. Nos services d’audit visent à évaluer la conformité de vos pratiques comptables et financières, à anticiper les risques et à vous aider à améliorer vos processus internes. Grâce à nos audits internes et externes, vous bénéficiez d’une gestion proactive des risques financiers. Contactez nos experts pour en savoir plus.",
        service9: "Gestion financière et Gestion d’actifs  ",
        service10:
          "DELATRUST vous accompagne dans la gestion financière quotidienne et la valorisation de vos actifs, en offrant des solutions sur mesure pour optimiser vos performances. Nos services incluent un suivi rigoureux de vos flux financiers, la gestion des risques et la planification stratégique afin d’atteindre vos objectifs à long terme. Contactez nos experts pour en savoir plus.",
        service11: "Services Corporate et Fiduciaires",
        service12:
          "Nos services fiduciaires incluent la domiciliation d'entreprise, la gestion de projet, le conseil et les services de secrétariat, offrant ainsi un accompagnement global pour les aspects juridiques et administratifs de vos affaires. Nous vous guidons dans le choix de la forme juridique la plus appropriée à vos activités, en tenant compte des implications fiscales. De la création à la dissolution d’entreprise, en passant par les restructurations et transformations (fusions et acquisitions), DELATRUST vous accompagne à chaque étape pour assurer le succès de vos projets. Contactez nos experts pour en savoir plus.",
        service13: "Internationalisation et Assistance en cas de litige  ",
        service14:
          "DELATRUST facilite votre développement à l'international, notamment sur les marchés africains en plein essor. Nous vous accompagnons dans la structuration et l'optimisation de vos opérations transfrontalières en veillant à sécuriser vos échanges. En cas de différends avec des partenaires locaux, nous intervenons à vos côtés en fournissant une assistance stratégique et une représentation efficace pour défendre vos intérêts. Contactez nos experts pour en savoir plus.",
          service15: "Ces services reflètent notre volonté de fournir à nos clients des solutions complètes, innovantes et adaptées à leurs besoins spécifiques, tout en garantissant le respect des normes et des réglementations en vigueur.",
        },
      contact: {
        contact1: "Contacts",
        contact2: "Visitez-nous",
        contact3: "Appelez-nous",
        contact4: "Ecrivez-nous",
        contact5: "Nom et prénoms",
        contact6: "Email",
        contact7: "Sujet",
        contact8: "Message",
        contact9: "Traitement...",
        contact10: "Votre message a été envoyé. Merci !",
        contact11: "Envoyer",
      },
      expert: {
        contenu:"<p>L'&eacute;quipe de DELATRUST</p><p>Notre &eacute;quipe de professionnels multilingues, ma&icirc;trisant l'anglais, l'allemand et/ou le fran&ccedil;ais, apporte une expertise approfondie en fiscalit&eacute; et en conformit&eacute; avec des normes comptables comme Lux GAAP, German GAAP, US GAAP et IFRS. Dirig&eacute;e par Mawo KOKOU, Expert-Comptable qualifi&eacute; avec de nombreuses ann&eacute;es d'exp&eacute;rience au Luxembourg et &agrave; l'international, DELATRUST s'impose comme un partenaire de confiance sur le march&eacute; luxembourgeois. Nous nous engageons &agrave; offrir des solutions sur mesure adapt&eacute;es aux besoins de nos clients, dans un environnement d'affaires en &eacute;volution continue. En int&eacute;grant les derni&egrave;res technologies, nous assurons des services efficaces, transparents et accessibles en temps r&eacute;el pour faciliter la gestion financi&egrave;re de nos clients.</p>",
        expert1: {
          nom: "Mawo KOKOU",
          titreMadal: "Le Directeur",
          fonction: "Directeur de DELATRUST",
          titre: "Expert-Comptable et diplômé en Management des entreprises, Mawo rejoint DELATRUST avec une expertise approfondie et une vision stratégique...",
          detail:
            ` <p><strong>Mawo KOKOU</strong><br />Expert-Comptable<br />Directeur DELATRUST<br />(Depuis Juin 2024)</p>
<p>Expert-Comptable et dipl&ocirc;m&eacute; en Management des entreprises, Mawo rejoint DELATRUST avec une expertise approfondie et une vision strat&eacute;gique, notamment en ce qui concerne l'exploration et l'exploitation des opportunit&eacute;s croissantes sur les march&eacute;s africains. Sous sa direction, DELATRUST est d&eacute;termin&eacute; &agrave; renforcer sa pr&eacute;sence internationale en offrant des solutions sur mesure qui r&eacute;pondent aux besoins sp&eacute;cifiques des entreprises interess&eacute;es par les march&eacute;s luxembourgeois et africains.</p>
            Sous sa direction, DELATRUST est d&eacute;termin&eacute; &agrave; renforcer sa pr&eacute;sence internationale en offrant des solutions sur mesure qui r&eacute;pondent aux besoins sp&eacute;cifiques des entreprises interess&eacute;es par les march&eacute;s luxembourgeois et africains.<br />Avec un accent particulier sur l&rsquo;innovation et la cr&eacute;ation de valeur, Mawo s&rsquo;engage &agrave; diriger DELATRUST vers de nouveaux horizons, en fournissant des services de qualit&eacute; sup&eacute;rieure &agrave; nos clients tout en explorant les nombreuses opportunit&eacute;s qu&rsquo;offre les march&eacute;s internationaux, en particulier les march&eacute;s africains. Ce focus strat&eacute;gique permettra &agrave; DELATRUST de jouer un r&ocirc;le cl&eacute; dans l&rsquo;accompagnement des entreprises face aux d&eacute;fis et aux possibilit&eacute;s d&rsquo;un march&eacute; en pleine expansion.<br />Nous sommes convaincus que Mawo apportera une contribution significative &agrave; notre cabinet et qu'il m&egrave;nera notre &eacute;quipe avec succ&egrave;s vers de nouvelles r&eacute;ussites.`,
        },
      },
      cabinetListe: [
        {
          nom: "WASSERBILLIG (LUXEMBOURG)",
          dirigeant: "Gérant : Mawo KOKOU",
          adresse: `<p>DELATRUST SARLS<br />7, Esplanade de la Moselle<br />L-6637 Wasserbillig<br />info@delatrust.lu</p>`,
        },
         {
          nom: "LOME (TOGO)",
          dirigeant: "Gérant : Kokou TINO",
          adresse: `<p>Y. &amp; Co Consulting<br />Av. Ak&eacute;&iuml; - Bld Concorde,&nbsp;05&nbsp;BP&nbsp;561<br />Lom&eacute;</p>lome@delatrust.lu`,
        },
        {
          nom: "DALLAS (USA)",
          dirigeant: "Gérant : Mawo KOKOU",
          adresse: `<p>AEA ACCOUNTING &amp; CONSULTING SERVICES LLC<br />9330 LBJ Freeway, Suite 900<br />75243 Dallas<br />dallas@delatrust.lu</p>`,
        },
        
        
      ],
      cabinet: {
        contenu:"<p>DELATRUST collabore &eacute;troitement avec un r&eacute;seau de cabinets partenaires au Luxembourg et &agrave; l&acute;&eacute;tranger, renfor&ccedil;ant ainsi notre expertise et notre capacit&eacute; &agrave; offrir des solutions compl&egrave;tes et adapt&eacute;es aux besoins internationaux de nos clients. Ces partenariats strat&eacute;giques avec des experts en fiscalit&eacute;, comptabilit&eacute; et droit des affaires nous permettent d&rsquo;assurer un accompagnement de qualit&eacute;.</p><p>Nos partenaires partagent notre engagement envers l'excellence et la conformit&eacute;, et nous travaillons ensemble pour soutenir des clients, en particulier dans le cadre de transactions transfrontali&egrave;res.</p><p>Ensemble, nous accompagnons nos clients dans l&rsquo;exploitation d&rsquo;opportunit&eacute;s d&rsquo;affaires, en assurant un support technique solide et une expertise locale qui r&eacute;pondent aux enjeux financiers et fiscaux globaux.</p>",
       
      },
      footer: {
        footer1: "Téléphone",
        footer2: "Email",
        footer3: "Liens utiles",
        footer4: "Ordre des Expert-Comptables de Luxembourg",
        footer5: "Administration des Contributions Directes",
        footer6: "Administration des Enregistrements des Domaines et de la TVA",
        footer7: "Mention légale",
        footer8: "Protection des données et confidentialité",
        footer9: "Autorisation d´établissement: 10170146/0",
      },
      mention:{
        titre:"Mention légale",
        contenu:`<p>Mentions l&eacute;gales de la soci&eacute;t&eacute; <strong>DELATRUST</strong></p>
<p>&nbsp;</p>
<p>D&eacute;nomination: DELATRUST S&agrave;rls<br /> Si&egrave;ge social: 7, Esplanade de la Moselle <br /> L-6637 Wasserbillig, Luxembourg<br /> Registre de commerce de Luxembourg: B288732<br /> Num&eacute;ro de TVA: LU35928783<br /> Autorisation d'&eacute;tablissement: 10170146/0</p>
<p>T&eacute;l&eacute;phone: +352 691 141 766<br /> WhatsApp: +352 691 141 766</p>
<p>Site internet: <a href="http://www.delatrust.lu">www.delatrust.lu</a><br /> Email: <a href="mailto:info@delatrust.lu">info@delatrust.lu</a></p>
<p>&nbsp;</p>
<p><strong>Conditions g&eacute;n&eacute;rales d'utilisation du site</strong></p>
<p>DELATRUST, cabinet d&rsquo;expertise comptable et fiscale inscrit &agrave; l&rsquo;Ordre des Experts-Comptables de Luxembourg, met &agrave; disposition son site internet www.delatrust.lu comme un espace d&eacute;di&eacute; &agrave; la pr&eacute;sentation de la soci&eacute;t&eacute; et de ses services. Ce site inclut des informations &agrave; caract&egrave;re informatif, publicitaire et marketing. Le contenu du site est susceptible d&rsquo;&ecirc;tre mis &agrave; jour ou modifi&eacute; &agrave; tout moment sans pr&eacute;avis.</p>
<p>L'utilisation du site implique l'acceptation des conditions g&eacute;n&eacute;rales d'utilisation. DELATRUST se r&eacute;serve le droit de modifier ces conditions &agrave; tout moment, les mises &agrave; jour s'appliquant imm&eacute;diatement &agrave; tous les utilisateurs.</p>
<p>&nbsp;</p>
<p><strong>Acc&egrave;s et contenu du site</strong></p>
<p>DELATRUST S&agrave;rls se r&eacute;serve le droit de mettre &agrave; jour, modifier, suspendre ou interrompre le site sans pr&eacute;avis pour des raisons de maintenance ou pour tout autre motif n&eacute;cessaire.</p>
<p>&nbsp;</p>
<p><strong>Propri&eacute;t&eacute; intellectuelle</strong></p>
<p>Tous les &eacute;l&eacute;ments pr&eacute;sents sur le site (logos, marques, photos, vid&eacute;os, textes, dessins, graphiques, mise en page) sont la propri&eacute;t&eacute; exclusive de DELATRUST. Toute reproduction, distribution, modification ou utilisation, partielle ou totale, du contenu est strictement interdite sans autorisation pr&eacute;alable.</p>
<p>&nbsp;</p>
<p><strong>Liens vers d&rsquo;autres sites</strong></p>
<p>Le site de DELATRUST peut contenir des liens vers des sites tiers. DELATRUST n&rsquo;approuve, ni ne contr&ocirc;le le contenu de ces sites et d&eacute;cline alors toute responsabilit&eacute; quant aux informations et services propos&eacute;s par ces tiers.</p>`,
      politique:`
<p>Les donn&eacute;es collect&eacute;es via les formulaires du site sont enregistr&eacute;es uniquement apr&egrave;s validation de l&rsquo;utilisateur, ce qui constitue un consentement explicite &agrave; leur traitement. Vous avez le droit de consulter, faire corriger ou supprimer vos donn&eacute;es personnelles en envoyant une demande par email &agrave; info@delatrust.lu ou par courrier postal &agrave; l&rsquo;adresse du si&egrave;ge social.</p>
<p>Toute fois, la soci&eacute;t&eacute; DELATRUST S&agrave;rls se reserve le droit de ne pas conserver les donn&eacute;es personnelles des utilisateurs et donc pourra les supprimer &agrave; tout moment sans pr&eacute;avis.</p>
<p><strong>Loi applicable</strong></p>
<p>Les pr&eacute;sentes conditions sont soumises &agrave; la l&eacute;gislation luxembourgeoise. En cas de diff&eacute;rend li&eacute; &agrave; l'application ou &agrave; l'interpr&eacute;tation de ces conditions, les juridictions comp&eacute;tentes du Grand-Duch&eacute; de Luxembourg seront saisies.</p>`,

titrePolitique:"Protection des données et confidentialité"},
      info: [
        {
          title:
            "31 décembre : Délai de dépôt de la Déclaration pour l'impôt sur le revenu, l'impôt commercial et l'impôt sur la fortune des collectivités",
          content:  "N'oubliez pas que la déclaration fiscale doit être soumise au bureau d'imposition compétent avant le 31 décembre de l'année suivant... ",
          content2:  "N'oubliez pas que la déclaration fiscale doit être soumise au bureau d'imposition compétent avant le 31 décembre de l'année suivant l'année d'imposition concernée. Ne tardez pas à préparer vos documents. Contactez-nous pour en savoir plus ! ",
        },
        {
          title: "31 juillet : Délai de dépôt des comptes annuels  ",
          content:
            "Les comptes annuels doivent être déposés dans le mois suivant leur approbation, soit au plus tard 7 mois après la clôture de l’année civile pour les commerçants personnes physiques, ou après la clôture de ",
        },
        {
          title:
            "Avant le 1er mars ou le 1er mai : Délai de dépôt de la déclaration TVA annuelle  ",
          content:
            "Le délai de dépôt de la déclaration de TVA annuelle dépend du chiffre d’affaires global hors taxe, à moins qu'une autre décision soit prise par l'Administration de l'Enregistrement et des Domaines (AED). Contactez-nous pour en savoir plus !",
        },
        {
          title: "Obligations fiscales au Luxembourg  ",
          content:
            "La création et la gestion d’une entreprise au Luxembourg sont soumises à diverses taxes et obligations fiscales, régies par trois administrations principales : l'Administration des Contributions Directes (ACD), ",
        },
      ],
      sendmail : {
        confirmation:"Confirmez-vous l´envoi de ce message?",
        textConfirmation:"Oui",
        textCancel:"Non",
        textSuccess:"DELATRUST Luxembourg vous remercie pour votre message.Un expert vous contactera dès que possible.",
      },
      information :{
        info:"Détail de l'annonce",
        info1:"Sélectionnez une annonce dans la liste à gauche pour voir les détails ici."
      }
    },
  },
};
