<template>
  <div class="index-page">
    <header id="header" class="header sticky-top">
      <!-- <div class="topbar d-flex align-items-center">
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-envelope d-flex align-items-center">
              <a href="mailto:contact@example.com">contact@example.com</a>
            </i>
            <i class="bi bi-phone d-flex align-items-center ms-4">
              <span>+1 5589 55488 55</span>
            </i>
          </div>
          <div class="social-links d-none d-md-flex align-items-center">
            <a href="#" class="twitter">
              <i class="bi bi-twitter-x"></i>
            </a>
            <a href="#" class="facebook">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="#" class="instagram">
              <i class="bi bi-instagram"></i>
            </a>
            <a href="#" class="linkedin">
              <i class="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </div>-->
      <!-- End Top Bar -->

      <div class="branding d-flex align-items-center">
        <div class="container position-relative d-flex align-items-center justify-content-between">
          <a href="#hero" class="logo d-flex align-items-center me-auto">
            <!-- Uncomment the line below if you also wish to use an image logo -->
            <img src="assets/img/logo.png" alt />
            <!-- <h1 class="sitename">Medilab</h1> -->
          </a>

          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <a href="#hero" class="active">
                  {{ $t("message.menu.menu1") }}
                  <br />
                </a>
              </li>
              <li>
                <a href="#about">{{ $t("message.menu.menu2") }}</a>
              </li>
              <li>
                <a href="#services">{{ $t("message.menu.menu3") }}</a>
              </li>
              <li class="dropdown">
                <a href="#">
                  <span>{{ $t("message.menu.menu6.sousmenu1") }}</span>
                  <i class="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#quisommesnous">
                      {{
                      $t("message.menu.menu6.sousmenu5")
                      }}
                    </a>
                  </li>
                  <!-- <li>
                    <a href="#objectifs">
                      {{
                      $t("message.menu.menu6.sousmenu2")
                      }}
                    </a>
                  </li> -->
                  <li>
                    <a href="#experts">
                      {{
                      $t("message.menu.menu6.sousmenu3")
                      }}
                    </a>
                  </li>
                  <li>
                    <a href="#cabinets">
                      {{
                      $t("message.menu.menu6.sousmenu4")
                      }}
                    </a>
                  </li>
                </ul>
              </li>
              
              <!-- <li>
                <a href="#departments">Departments</a>
              </li>-->
              <!-- <li>
                <a href="#doctors">Doctors</a>
              </li>
              <li class="dropdown">
                <a href="#">
                  <span>Dropdown</span>
                  <i class="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Dropdown 1</a>
                  </li>
                  <li class="dropdown">
                    <a href="#">
                      <span>Deep Dropdown</span>
                      <i class="bi bi-chevron-down toggle-dropdown"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Dropdown 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Dropdown 2</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 3</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 4</a>
                  </li>
                </ul>
              </li>-->

              <li>
                <a href="#contact">{{ $t("message.menu.menu4") }}</a>
              </li>
              <li class="dropdown">
                <a href="#">
                  <span>{{ langue }}</span>
                  <i class="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#" @click="changerLangue('FR')">FR</a>
                  </li>
                  <li>
                    <a href="#" @click="changerLangue('DE')">DE</a>
                  </li>
                  <li>
                    <a href="#" @click="changerLangue('EN')">EN</a>
                  </li>
                </ul>
              </li>
              <li>
                <router-link
                      :to="{ name: 'annonces'}"
                    >{{ $t("message.menu.menu7") }}</router-link>
              </li>
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
          <input
            class="cta-btn d-none d-sm-block"
            type="button"
           @click.prevent="redirectToAdmin"
            :value="$t('message.menu.menu5')"
          />

           <!-- <a class="cta-btn d-none d-sm-block" href="#">Espace client</a>  -->
        </div>
      </div>
    </header>

    <main class="main">
      <!-- Hero Section -->
      <section id="hero" class="hero section light-background">
        <img src="assets/img/hero-bg.jpg" alt data-aos="fade-in" />

        <div class="container position-relative">
          <div class="welcome position-relative" data-aos="fade-down" data-aos-delay="100">
            <h2 style="color: rgb(43 3 245);">{{ $t("message.banner.titre1") }}</h2>
            <h4 style="color: rgb(43 3 245);">{{ $t("message.banner.titre2") }}</h4>
            <!-- <p>We are team of talented designers making websites with Bootstrap</p> -->
          </div>
          <!-- End Welcome -->

          <div class="content row gy-4">
            <div class="col-lg-4 d-flex align-items-stretch">
              <div class="icon-box icon-box2" data-aos="zoom-out" data-aos-delay="200">
                <i class="fa-solid fa-question-circle"></i>
                <h4>{{ $t("message.banner.pourquoi")  || '' }}</h4>
                <p v-html="$t('message.banner.pourquoi1')" ></p>
                <div class="text-center">
                  <b-button
                    href="#about"
                    variant="primary rounded-pill"
                    class="btn btn-primary"
                  >
                  {{ $t("message.banner.plus")  || '' }}
                  </b-button>
                </div>
              </div>
            </div>
            <!-- End Why Box -->

            <div class="col-lg-8 d-flex align-items-stretch">
              <!-- <div class="d-flex flex-column justify-content-center">
              <div class="row gy-4">-->
              <!-- <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box2 icon-box" data-aos="zoom-out" data-aos-delay="300">
                      <i class="fa-solid fa-balance-scale"></i>
                      <h4>{{ $t("message.banner.apropos") }}</h4>
                      <p>{{ $t("message.banner.apropos1") }}</p>
                    </div>
              </div>-->

              <!-- End Icon Box -->

              <!-- <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box" data-aos="zoom-out" data-aos-delay="400">
                      <i class="bi bi-gem"></i>
                      <h4>{{ $t("message.banner.apropos2") }}</h4>
                      <p>{{ $t("message.banner.apropos3") }}</p>
                    </div>
              </div>-->
              <!-- End Icon Box -->
              <!-- <div class="col-xl-12 d-flex align-items-stretch"> -->
              <!-- <div class="icon-box" data-aos="zoom-out" data-aos-delay="300">
                      <i class="bi bi-megaphone"></i>
                      <h4>{{ $t("message.banner.apropos6") }}</h4>
                      <vueper-slides
                        3d
                        fixed-height="200px"
                        autoplay
                        :slide-ratio="0.2"
                        :infinite="false"
                        disable-arrows-on-edges
                        bullets-outside
                        :disable="slideshowDisabled"
                      >
                        <vueper-slide
                          v-for="(slide, i) in slides"
                          :key="i"
                          :title="slide.title"
                          :content="slide.content"
                        ></vueper-slide>
                      </vueper-slides>
              </div>-->
              <div class="icon-box" data-aos="zoom-out" data-aos-delay="300">
                <i class="bi bi-megaphone"></i>
                <h4>{{ $t("message.banner.apropos6") || '' }}</h4>
                <!-- <vue-marquee-slider
                  id="marquee-slider-cards"
                  :space="100"
                  :speed="161000"
                  :width="420"
                >
                  <div class="col-xl-4 d-flex align-items-stretch" v-for="an in  $t('message.info')">
                    <div >
                      <h4>{{ an.title }}</h4>
                      <p>{{ an.content}}</p>
                    </div>
                  </div>
                </vue-marquee-slider>-->
                <b-modal
                  id="modal-annonce"
                  size="lg"
                  centered
                  :title="annonceContenu.titre "
                  :header-bg-variant="headerBgVariant"
                >
                  <div  v-html=" annonceContenu.description  || '' "></div>

                  <template #modal-footer>
                    <div class="w-100"></div>
                  </template>
                </b-modal>
                <Carousel
                  :value="annonces"
                  :numVisible="1"
                  :numScroll="1"
                  :circular="true"
                  :autoplayInterval="5000"
                  :responsiveOptions="responsiveOptions"
                >
                  <template #header></template>
                  <template #item="slotProps">
                    <div>
                      <h2 style="text-transform: uppercase;">{{ slotProps.data.titre   || ''}}</h2>
                      <p>{{ slotProps.data.recap   || ''}}</p>
                      <p>
                        <b-button
                          v-b-modal.modal-annonce
                          variant="primary rounded-pill"
                          class="btn btn-primary"
                          @click.prevent="annonceCont(slotProps.data)"
                        >{{ $t("message.banner.plus") }}</b-button>
                      </p>
                    </div>
                  </template>
                </Carousel>
              </div>
              <!-- End Icon Box -->

              <!-- </div> -->
              <!-- </div>
              </div>-->
            </div>
          </div>
          <!-- End  Content-->
        </div>
      </section>
      <!-- /Hero Section -->

      <!-- About Section -->
      <section id="about" class="about section">
        <div class="container">
          <div class="row gy-4 gx-5 content">
            <h3>{{ $t("message.apropos.apropos1")  || '' }}</h3>
            <p>
              {{ $t("message.apropos.apropos2")  || ''}}
              <br />
              {{ $t("message.apropos.apropos3") || '' }}
            </p>
          </div>
          <hr class="my-hr" />
          <div class="row gy-4 gx-5">
            <div class="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
              <h3>{{ $t("message.apropos.mission.mission")  || ''}}</h3>
              <ul>
                <li>
                  <i class="fa-solid fa-vial-circle-check"></i>
                  <div>
                    <h5>{{ $t("message.apropos.mission.mission1")  || ''}}</h5>
                    <p>{{ $t("message.apropos.mission.mission2")  || ''}}</p>
                  </div>
                </li>
                <li>
                  <i class="fa-solid fa-heart-circle-xmark"></i>
                  <div>
                    <h5>{{ $t("message.apropos.mission.mission3")  || '' }}</h5>
                    <p>{{ $t("message.apropos.mission.mission4")  || ''}}</p>
                  </div>
                </li>
                <li>
                  <i class="fa-solid fa-pump-medical"></i>
                  <div>
                    <h5>{{ $t("message.apropos.mission.mission5")  || ''}}</h5>
                    <p>{{ $t("message.apropos.mission.mission6")  || ''}}</p>
                  </div>
                </li>
                <li>
                  <i class="fa-solid fa-pump-medical"></i>
                  <div>
                    <h5>{{ $t("message.apropos.mission.mission7")  || ''}}</h5>
                    <p>{{ $t("message.apropos.mission.mission8")  || ''}}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
              <h3>{{ $t("message.apropos.valeur.valeur") }}</h3>
              <ul>
                <li>
                  <i class="fa-solid fa-shield-alt"></i>
                  <div>
                    <h5>{{ $t("message.apropos.valeur.valeur1") }}</h5>
                    <p>{{ $t("message.apropos.valeur.valeur2") }}</p>
                  </div>
                </li>
                <li>
                  <i class="fa-solid fa-headset"></i>
                  <div>
                    <h5>{{ $t("message.apropos.valeur.valeur3") }}</h5>
                    <p>{{ $t("message.apropos.valeur.valeur4") }}</p>
                  </div>
                </li>
                <li>
                  <i class="fa-solid fa-hands-helping"></i>
                  <div>
                    <h5>{{ $t("message.apropos.valeur.valeur5") }}</h5>
                    <p>{{ $t("message.apropos.valeur.valeur6") }}</p>
                  </div>
                </li>
                <li>
                  <i class="fa-solid fa-hands-helping"></i>
                  <div>
                    <h5>{{ $t("message.apropos.valeur.valeur7") }}</h5>
                    <p>{{ $t("message.apropos.valeur.valeur8") }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- /About Section -->


      <!-- Services Section -->
      <section id="services" class="services section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>{{ $t("message.service.service1") }}</h2>
         
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            <p>{{ $t("message.service.service2") }}</p>
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-calculator"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>{{ $t("message.service.service3") }}</h3>
                </a>
                <p>{{ $t("message.service.service4") }}</p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-file-alt"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>{{ $t("message.service.service5") }}</h3>
                </a>
                <p>{{ $t("message.service.service6") }}</p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-file-invoice-dollar"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>{{ $t("message.service.service7") }}</h3>
                </a>
                <p>{{ $t("message.service.service8") }}</p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-briefcase"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>{{ $t("message.service.service9") }}</h3>
                </a>
                <p>{{ $t("message.service.service10") }}</p>
                <a href="#" class="stretched-link"></a>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-gavel"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>{{ $t("message.service.service11") }}</h3>
                </a>
                <p>{{ $t("message.service.service12") }}</p>
                <a href="#" class="stretched-link"></a>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-headset"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>{{ $t("message.service.service13") }}</h3>
                </a>
                <p>{{ $t("message.service.service14") }}</p>
                <a href="#" class="stretched-link"></a>
              </div>
            </div>
            <!-- End Service Item -->
          </div>
        </div>
        <br />
        <div class="container" data-aos="fade-up">
          {{ $t("message.service.service15") }}
        </div>
      </section>
      <!-- /Services Section -->

      <!-- cabinet Section -->
      <section id="quisommesnous" class="doctors section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>{{ $t("message.menu.menu6.sousmenu5") }}</h2>
          <div></div>
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            
           
            <div class="col-lg-12">
              <div v-html="$t('message.quisommesnous.contenu')" data-aos="fade-up" data-aos-delay="100"></div>
            </div>
            <!-- End Team Member -->
          </div>
        </div>
      </section>
      <!-- /cabinet Section -->

      <!-- experts Section -->
      <section id="experts" class="doctors section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>{{ $t("message.menu.menu6.sousmenu3") }}</h2>
          <!-- <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> -->
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
           
            <div class="col-lg-6">
              <div v-html="$t('message.expert.contenu')" data-aos="fade-up" data-aos-delay="100"></div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div class="team-member d-flex align-items-start">
                <div class="member-info me-4">
                  <h4>{{ $t("message.expert.expert1.nom") }}</h4>
                  <span>{{ $t("message.expert.expert1.fonction") }}</span>
                  <p>{{ $t("message.expert.expert1.titre") }}</p>
                  <div class="social">
                    <div>
                      <b-button
                        v-b-modal.modal-center
                        variant="primary rounded-pill"
                        class="btn btn-primary"
                      >{{ $t("message.banner.plus") }}</b-button>

                      <b-modal
                        id="modal-center"
                        size="lg"
                        centered
                        :title="$t('message.expert.expert1.titreMadal')"
                        :header-bg-variant="headerBgVariant"
                      >
                        <div v-html="$t('message.expert.expert1.detail')"></div>
                        <!-- <p class="my-4">{{ $t("message.expert.expert1.detail") }}</p> -->
                        <template #modal-footer>
                          <div class="w-100"></div>
                        </template>
                      </b-modal>
                    </div>
                  </div>
                </div>
                <div class="pic">
                  <img src="assets/img/dg2.jpg" alt class="img-fluid" />
                  <!-- <img src="assets/img/doctors/doctors-1.jpg" class="img-fluid" alt /> -->
                </div>
              </div>
            </div>
            <!-- End Team Member -->
          </div>
        </div>
      </section>
      <!-- /experts Section -->
      <!-- <section id="faq" class="faq section light-background"></section> -->

      <!-- cabinet Section -->
      <section id="cabinets" class="doctors section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>{{ $t("message.menu.menu6.sousmenu4") }}</h2>
          <div></div>
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            
            <div class="col-lg-6">
              <div class="row"  >
                <div class="col-lg-6 py-2 " v-for="(item, index) in $t('message.cabinetListe')" :key="index" data-aos="fade-up" data-aos-delay="100">
                  <div class="team-member d-flex align-items-start">
                    <div class="member-info me-4">
                      <h4>{{item.nom }}</h4>
                      <!-- <span>{{ item.dirigeant }}</span> -->
                      <p v-html="item.adresse"></p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-lg-6">
              <div v-html="$t('message.cabinet.contenu')" data-aos="fade-up" data-aos-delay="100"></div>
            </div>
            <!-- End Team Member -->
          </div>
        </div>
      </section>
      <!-- /cabinet Section -->

      <!-- Contact Section -->
      <section id="contact" class="contact section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>{{ $t("message.contact.contact1") }}</h2>
          <!-- <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> -->
        </div>
        <!-- End Section Title -->

        <!-- <div class="mb-5" data-aos="fade-up" data-aos-delay="200">
          <iframe
            style="border:0; width: 100%; height: 270px;"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus"
            frameborder="0"
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>-->
        <!-- End Google Maps -->

        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row gy-4">
            <div class="col-lg-4">
              <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                <i class="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>{{ $t("message.contact.contact2") }}</h3>
                  <p>7 Esplanade de la Moselle </p><p></p> L-6637 Wasserbillig Luxemburg</p>
                </div>
              </div>
              <!-- End Info Item -->

              <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                <i class="bi bi-telephone flex-shrink-0"></i>
                <div>
                  <h3>{{ $t("message.contact.contact3") }}</h3>
                  <p>+352 691 141 766</p>
                </div>
              </div>
              <!-- End Info Item -->

              <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                <i class="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>{{ $t("message.contact.contact4") }}</h3>
                  <p>info@delatrust.lu</p>
                </div>
              </div>
              <!-- End Info Item -->
            </div>

            <div class="col-lg-8">
              <form
                
                method="post"
                class="php-email-form"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="row gy-4">
                  <div class="col-md-6">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      :placeholder="$t('message.contact.contact5')"
                      v-model="message.nom"
                      required
                    />
                  </div>

                  <div class="col-md-6">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      :placeholder="$t('message.contact.contact6')"
                      v-model="message.email"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      :placeholder="$t('message.contact.contact7')"
                      v-model="message.sujet"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="6"
                      :placeholder="$t('message.contact.contact8')"
                      required
                      v-model="message.message"
                    ></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <!-- <div class="loading">{{ $t("message.contact.contact9") }}</div>
                    <div class="error-message"></div>
                    <div class="sent-message">{{ $t("message.contact.contact10") }}</div> -->

                    <button type="submit" @click.prevent="sendmail()" >{{ $t("message.contact.contact11") }}</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- End Contact Form -->
          </div>
        </div>
      </section>
      <!-- /Contact Section -->
    </main>

    <footer id="footer" class="footer light-background">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-6 footer-about">
            <!-- <h4>
            </h4>-->
            <strong>DELATRUST Sàrls</strong>

            <br />
            {{ $t("message.banner.titre2") }}
            <br />RCS: B288732
            <br />
            {{ $t("message.footer.footer9") }}
            <br />
            <br />
            <p>
              +352 691 141 766
              <!-- <br />+49 171 988 4284 -->
              <!-- <br />m.kokou@delatrust.lu -->
            </p>
            <p>
              7, Esplanade de la Moselle
              <br />
              L-6637 Wasserbillig
            </p>
            <p>
              <!-- <img src="assets/img/logo.png" alt width="164" height="49" /> -->
            </p>
            <div class="social-links d-flex mt-4">
              <!-- <a href>
                <i class="bi bi-twitter-x"></i>
              </a>
              <a href>
                <i class="bi bi-facebook"></i>
              </a>
              <a href>
                <i class="bi bi-instagram"></i>
              </a> -->
              <a href ="https://www.linkedin.com/company/delatrust-luxembourg" target="_blank">
                <i class="bi bi-linkedin"></i>
              </a>
              <a href="https://wa.me/352691141766" target="_blank">
                <i class="bi bi-whatsapp"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>{{ $t("message.footer.footer3") }}</h4>
            <ul>
              <li>
                <a href="https://oec.lu/myeteam/index.htm#HOME/3" target="_blank">{{ $t("message.footer.footer4") }}</a>
              </li>
              <li>
                <a href="https://impotsdirects.public.lu/fr.html" target="_blank">{{ $t("message.footer.footer5") }}</a>
              </li>
              <li>
                <a href="https://aed.gouvernement.lu/fr.html" target="_blank">{{ $t("message.footer.footer6") }}</a>
              </li>
              <li>
                <router-link :to="{ name: 'mention-legal' }">{{ $t("message.footer.footer7") }}</router-link>
                
              </li>
              <li>
                <router-link :to="{ name: 'protection-donnees' }">{{ $t("message.footer.footer8") }}</router-link>
                
              </li>
              <li>
                <router-link :to="{ name: 'annonces' }">Annonces</router-link>
                
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>{{ $t("message.service.service1") }}</h4>
            <ul>
              <li>
                <a href="#">{{ $t("message.service.service3") }}</a>
              </li>
              <li>
                <a href="#">{{ $t("message.service.service5") }}</a>
              </li>
              <li>
                <a href="#">{{ $t("message.service.service7") }}</a>
              </li>
              <li>
                <a href="#">{{ $t("message.service.service9") }}</a>
              </li>
              <li>
                <a href="#">{{ $t("message.service.service11") }}</a>
              </li>
            </ul>
          </div>

          <!-- <div class="col-lg-2 col-md-3 footer-links">
            <h4>Hic solutasetp</h4>
            <ul>
              <li>
                <a href="#">Molestiae accusamus iure</a>
              </li>
              <li>
                <a href="#">Excepturi dignissimos</a>
              </li>
              <li>
                <a href="#">Suscipit distinctio</a>
              </li>
              <li>
                <a href="#">Dilecta</a>
              </li>
              <li>
                <a href="#">Sit quas consectetur</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Nobis illum</h4>
            <ul>
              <li>
                <a href="#">Ipsam</a>
              </li>
              <li>
                <a href="#">Laudantium dolorum</a>
              </li>
              <li>
                <a href="#">Dinera</a>
              </li>
              <li>
                <a href="#">Trodelas</a>
              </li>
              <li>
                <a href="#">Flexo</a>
              </li>
            </ul>
          </div>-->
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>
          <!-- © -->
          <!-- <span>Copyright</span> -->
          <!-- <strong class="px-1 sitename">Medilab</strong> -->
          <!-- <span>All Rights Reserved</span> -->
        </p>
      </div>
    </footer>

    <!-- Scroll Top -->
    <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center">
      <i class="bi bi-arrow-up-short"></i>
    </a>
    <CookieConsent />
    <!-- Preloader -->
    <!-- <div id="preloader"></div> -->
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapActions, mapGetters } from "vuex";
import CookieConsent from "../components/CookieConsent.vue"; // Importer le composant CookieConsent

export default {
  name: "HomeView",
  components: {
    VueperSlides,
    VueperSlide,
    CookieConsent
    // HelloWorld
  },
  data() {
    return {
      form: {
        lastName: "",
        firstName: "",
        topic: "",
        email: "",
        text: ""
      },
      message:{},
      annonceContenu: "",
      file: null,
      show: true,
      langue: "",
      display: false,
      slides: this.$t("message.info"),
      // slides: [
      //   {
      //     title: "Slide #1",
      //     content: "Slide 1 content."
      //   },
      //   {
      //     title: "Slide #2",
      //     content: "Slide 2 content."
      //   }
      // ]
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      // annonces: "annonce/annonces",
      annonces: "annonce/annonces",
      pagination: "annonce/pagination"
    })
  },
  created() {
    this.fetchAnnonces(this.$i18n.locale);
  },
  
  mounted() {
    this.langue = this.$i18n.locale;
    // Vérifier si un hash existe dans l'URL
    if (window.location.hash) {
      // Si le hash existe, récupérer l'élément cible
      const targetElement = document.querySelector(window.location.hash);
      
      // Si l'élément existe, faire défiler vers cet élément
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',  // Positionner l'élément au début de la fenêtre de visualisation
        });
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAnnonces: "annonce/fetchAnnonces",
      createOrUpdateContact: "contact/createOrUpdateContact"
    }),
    sendmail() {

      if (this.message.nom==null){
        return
      }

      if (this.message.email==null){
        return
      }

      if (this.message.sujet==null){
        return
      }

      if (this.message.message==null){
        return
      }

      this.$swal
        .fire({
          title: "Confirmation",
          text:  this.$t("message.sendmail.confirmation"),
          // icons: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t("message.sendmail.textCancel"),
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("message.sendmail.textConfirmation"),
        })
        .then(result => {
          if (result.value == true) {

        this.createOrUpdateContact(this.message).then(result => {
                this.message = {};

                // this.$toast.open({
                //   type: "success",
                //   message: "Success",
                //   position: "top-right"
                // });
                this.$swal.fire({
  title: "Information",
  text: this.$t("message.sendmail.textSuccess"),
  icon: "info",
  confirmButtonText: "OK",
  confirmButtonColor: "#3085d6"
});
              })
              .catch(err => {
                console.log(err);
                this.$toast.open({
                  type: "error",
                  message: "Opération échouée",
                  position: "top-right"
                });
              });
            }
          });
    },
    annonceCont(payload) {
      this.annonceContenu = payload;
    },
    plusAnnonce() {
      this.display ? true : false;
    },
    changerLangue(langue) {
      // this.langue = langue
      localStorage.setItem("langue", langue); // Sauvegarde la langue dans le navigateur
      window.location.reload();
      // this.$i18n.locale = langue; // Change la langue
    },
    redirectToAdmin() {
      window.location.href = "https://www.delatrust.lu/admin/";
    }
  }
};
</script>

<style scoped>
.responsive-img {
  max-width: 300%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 990px) {
  /* Vous pouvez ajuster la largeur max selon vos besoins */
  .responsive-img {
    max-width: 100%;
  }
}

.my-hr2 {
  border: 2px dashed #007bff;
  /* Ligne bleue avec bordure en pointillés */
  opacity: 0.5;
  /* Transparence de 50% */
}

.my-hr {
  border: 0; 
  height: 2px; 
  background: #007bff; 
  /* Couleur bleue */
  opacity: 0.5; 
  /* Transparence de 50% */
  margin: 10px 0;
  /* Espacement au-dessus et en dessous */
}

.vertical-line {
  border-left: 2px solid #007bff;
  /* Ligne verticale bleue */
  height: 100px;
  /* Hauteur de la ligne */
}

.icon-box2 {
  /* Styles existants ou ajoutés */
  /* Exemple de personnalisation supplémentaire */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  /* Centrer le texte si nécessaire */
  color: #000;
  /* Couleur du texte en noir */
}

/* Surcharge spécifique pour le texte <h3> à l'intérieur de .icon-box */
.icon-box2 h4 {
  color: #000;
  /* Couleur du texte en noir */
  font-size: 1.5em;
  /* Ajustez la taille de la police selon vos besoins */
  margin: 0;
  /* Élimine les marges par défaut */
  padding: 10px 0;
  /* Espacement autour du texte */
}

.more-btn2 {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  font-size: 0.875em;
  /* Taille de la police petite */
  padding: 5px 10px;
  /* Réduit l'espacement interne */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.more-btn2:hover {
  background-color: #0056b3;
}

/* Optionnel : Vous pouvez définir des styles supplémentaires pour des boutons de petite taille */
.btn-sm2 {
  font-size: 0.875em;
  padding: 5px 10px;
}

.text-noir {
  color: #000;
  /* Couleur du texte en noir */
}

div {
  font-family: Verdana, sans-serif;
  line-height: 1.6;
}
</style>
